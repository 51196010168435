import {
  AnyAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit';

import { create, read, update, edit } from 'utils/api';
import { useAppSelector } from 'store/hooks';
import { setShowShoppingCartWarning } from 'store/order';
import { readUserDataThunk } from 'store/buyer/editBuyerData';
import { RootState } from 'store/index';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';

import {
  CartState,
  RemoveFromCartPayload,
  ChangeStatusFromCartPayload,
  ProductsDeliveryDurationsInterface,
  GetShoppingCartRequestParams,
  ShowWarning,
  AddProductInTheShoppingCartRequestParams,
  GetOutOfDeliveryParams,
  UpdatedDeliveryDurationRequestParams,
  DeliveryPrice,
  ShopDeliveryPrices,
  PartnersDeliveryPrices,
  ShoppingCartProductIds,
} from './types';

export const checkOutOfDelivery = async ({ lat, lng }: GetOutOfDeliveryParams) => {
  if (+lng < 0) return true;

  const response = await read(`${REACT_APP_BUYER_URL}/in-no-delivery-zone?lat=${lat}&lng=${lng}`);

  const { outOfDelivery } = response as any;

  return outOfDelivery;
};

const getShoppingCartDurationsForLoggedOutUser = async (
  { lat, lng, products, enableDurationPostalCode }: ProductsDeliveryDurationsInterface,
  { dispatch }: any
) => {
  if (!products.length) {
    return { data: {}, count: 0, outOfDelivery: false };
  }
  const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
  let url = REACT_APP_BUYER_URL;

  const payloadData = { products } as any;
  let outOfDelivery = false;
  if (enableDurationPostalCode && postalCode) {
    url = REACT_APP_BUYER_URL_V2;
    payloadData.postalCode = postalCode;
  } else {
    outOfDelivery = await checkOutOfDelivery({ lat, lng });
    payloadData.lat = lat;
    payloadData.lng = lng;
  }

  const response = await create(`${url}/products/delivery-duration`, payloadData);
  let initialCount = 0;
  let count = 0;
  const result = {};
  const { data } = response;

  products.forEach(({ count }) => {
    initialCount += count;
  });

  Object.entries(data).forEach(([shop, products]: any) => {
    if (!result[shop]) {
      result[shop] = [];
    }

    products.forEach(product => {
      const {
        count: productCount,
        product: { id: productId },
        isActive,
      } = product;
      count += productCount;
      const existingIndex = result[shop]?.findIndex(({ id }) => id === productId);

      if (existingIndex > -1) {
        result[shop][existingIndex] = {
          ...result[shop][existingIndex],
          count: result[shop][existingIndex].count + 1,
        };
      } else {
        result[shop].push({
          id: productId,
          count: 1,
          isActive,
          product: {
            id: productId,
          },
        });
      }
    });
  });

  if (initialCount > count) {
    dispatch(
      setShowShoppingCartWarning({
        showCheckShoppingCartWarning: true,
        checkShoppingCartText:
          'Количество товаров в вашей корзине изменилось, проверьте корзину перед оформлением заказа.',
      })
    );

    localStorage.setItem('cartProducts', JSON.stringify(result));
  }
  return { data, count, outOfDelivery };
};

export const getShoppingCartDurationsForLoggedOutUserThunk = createAsyncThunk(
  'products/deliery-durations',
  getShoppingCartDurationsForLoggedOutUser
);

const getLoggedOutUserCartCount = async ({ products }) => {
  const response = await create(`${REACT_APP_BUYER_URL}/products/count-in-cart`, { products });
  return response;
};

export const getLoggedOutUserCartCountThunk = createAsyncThunk(
  'loggedOutUser/cart/count',
  getLoggedOutUserCartCount
);

const addProductCountInShoppingCart = async (
  data: AddProductInTheShoppingCartRequestParams,
  { dispatch, rejectWithValue }
) => {
  const {
    productId,
    makeActive = false,
    refreshShoppingCart = false,
    enableDurationPostalCode,
    callback,
  } = data;
  const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
  try {
    let url = REACT_APP_BUYER_URL;
    const payloadData = { productId } as { productId: number; postalCode?: string };
    if (enableDurationPostalCode) {
      url = REACT_APP_BUYER_URL_V2;
      payloadData.postalCode = postalCode;
    }

    const response: any = await update(`${url}/shopping-cart`, payloadData);
    const { id } = response || {};
    if (makeActive) {
      dispatch(
        changeProductStatusThunk({
          data: {
            isActive: true,
            shopingCartIds: [id],
          },
          enableDurationPostalCode,
        })
      );
    }
    if (refreshShoppingCart) {
      const [lat, lng] = JSON.parse(sessionStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];

      await dispatch(
        getShoppingCartThunk({ lat, lng, postalCode, enableDurationPostalCode, showLoading: false })
      );
    } else {
      dispatch(readUserDataThunk(enableDurationPostalCode));
    }
    callback && callback();
    return response;
  } catch (err: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = err;

    if (code === 739) {
      return rejectWithValue(code);
    }

    callback && callback();
  }
};

export const addProductCountInShoppingCartThunk = createAsyncThunk(
  'cart/addCount',
  addProductCountInShoppingCart
);

export const getShoppingCart = async ({
  lat,
  lng,
  postalCode,
  enableDurationPostalCode,
  selectedProducts = [],
  isUpdatedDeliveryDuration = false,
}: GetShoppingCartRequestParams) => {
  let url = REACT_APP_BUYER_URL;

  const params = {} as {
    postalCode: string;
    lat: string;
    lng: string;
    sendPickupDuration: boolean;
    sendDeliveryDuration: boolean;
  };

  let outOfDelivery = false;
  if (enableDurationPostalCode && postalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = postalCode;
  } else {
    outOfDelivery = await checkOutOfDelivery({ lat, lng });
    if (outOfDelivery) {
      params.sendPickupDuration = true;
    } else {
      params.sendDeliveryDuration = true;
    }
    params.lat = lat;
    params.lng = lng;
  }

  const response = await read(`${url}/shopping-cart`, { params });

  if (selectedProducts.length && isUpdatedDeliveryDuration) {
    const data = {
      shopingCartIds: [],
      isActive: true,
    };
    const updatedProductsData = {};

    Object.entries(response?.data || {}).forEach(([shop, products]: any) => {
      updatedProductsData[shop] = products.map(product => {
        const { isActive, id, isAvailable } = product;
        if (!isActive && isAvailable && selectedProducts.includes(id)) {
          data.shopingCartIds.push(id);
          return { ...product, isActive: true };
        }
        return product;
      });
    });

    if (data.shopingCartIds.length) {
      await edit(`${REACT_APP_BUYER_URL}/change-product-status`, data);
      return { data: updatedProductsData, outOfDelivery };
    }
  }

  return { ...response, outOfDelivery };
};

export const getShoppingCartThunk = createAsyncThunk('cart/get', getShoppingCart);

const removeFromCart = async (
  {
    shopingCartIds,
    callback,
    enableDurationPostalCode,
    getShoppingCart = true,
  }: RemoveFromCartPayload,
  { dispatch }
) => {
  try {
    const response = await create(`${REACT_APP_BUYER_URL}/delete-cart-products`, {
      shopingCartIds,
    });
    if (getShoppingCart) {
      const [lat, lng] = JSON.parse(sessionStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
      const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
      await dispatch(
        getShoppingCartThunk({ lat, lng, postalCode, enableDurationPostalCode, showLoading: false })
      );
    }
    callback && callback();
    return response;
  } catch (err) {
    callback && callback();
  }
};

export const removeFromShoppingCartThunk = createAsyncThunk('cart/remove', removeFromCart);

const changeProductStatus = async (
  { data, getShoppingCart, enableDurationPostalCode }: ChangeStatusFromCartPayload,
  { dispatch }
) => {
  const response = await edit(`${REACT_APP_BUYER_URL}/change-product-status`, data);

  if (getShoppingCart) {
    const [lat, lng] = JSON.parse(sessionStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
    const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
    dispatch(
      getShoppingCartThunk({ showLoading: false, postalCode, enableDurationPostalCode, lat, lng })
    );
  }

  return response;
};

export const changeProductStatusThunk = createAsyncThunk(
  'cart/changeProductStatus',
  changeProductStatus
);

export const getProductStock = async (id: number, enableDurationPostalCode: boolean) => {
  const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
  let url = REACT_APP_BUYER_URL;
  const params = {} as { postalCode?: string };
  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = postalCode;
  }
  const response = await read(`${url}/products/${id}/stock`, { params });
  return response;
};

const updatedDeliveryDurationProducts = async (
  { lat, lng, postalCode, enableDurationPostalCode }: UpdatedDeliveryDurationRequestParams,
  { getState }
) => {
  const {
    cart: { selectedProducts },
  } = getState();

  const response = await getShoppingCart({
    lat,
    lng,
    postalCode,
    enableDurationPostalCode,
    selectedProducts,
    isUpdatedDeliveryDuration: true,
  });
  return response;
};

export const updatedDeliveryDurationProductsThunk = createAsyncThunk(
  'cart/updatedDeliveryDuration',
  updatedDeliveryDurationProducts
);

const getDeliveryPrices = async () => {
  return await read(`${REACT_APP_BUYER_URL}/delivery-prices`);
};

export const getDeliveryPricesThunk = createAsyncThunk('cart/getDeliveryPrices', getDeliveryPrices);

const getShopDeliveryPrices = async (ids: string) => {
  return await read(`${REACT_APP_BUYER_URL}/shop-delivery-prices?ids=${ids}`);
};

export const getShopDeliveryPricesThunk = createAsyncThunk(
  'cart/getShopDeliveryPrices',
  getShopDeliveryPrices
);

const getPartnersDeliveryPrices = async (postalCode: string) => {
  if (postalCode) {
    return await read(`${REACT_APP_BUYER_URL_V2}/delivery-prices/${postalCode}`);
  }
};

export const getPartnersDeliveryPricesThunk = createAsyncThunk(
  'cart/getPartnersDeliveryPrices',
  getPartnersDeliveryPrices
);

export const getDeliveryAddressesList = async () => {
  return await read(`${REACT_APP_BUYER_URL_V2}/courier-delivery-settings?&limit=${5000}`);
};

export const getDeliveryAddressesListThunk = createAsyncThunk(
  'cart/getDeliveryAddressesList',
  getDeliveryAddressesList
);

export const activateProductForBuyNow = async () => {
  const buyNowShoppingCartId = +localStorage.getItem('buyNowShoppingCartId');
  if (!buyNowShoppingCartId) throw new Error();
  return await edit(`${REACT_APP_BUYER_URL_V2}/shopping-cart/${buyNowShoppingCartId}/activate`);
};

const getShoppingCartProductIds = async () => {
  return await read(`${REACT_APP_BUYER_URL}/shopping-cart-products`);
};
export const getShoppingCartProductIdsThunk = createAsyncThunk(
  'cart/getShoppingCartProductIds',
  getShoppingCartProductIds
);

const initialState: CartState = {
  data: {},
  loggedOutCart: {},
  cartLoading: 'idle',
  count: 0,
  addingToShoppingCartLoadingForProduct: null,
  selectedProducts: [],
  cartProductIds: [],
  addProductInShoppingCartStatus: 'idle',
  changeProductStatus: 'idle',
  showCheckoutSection: false,
  showWarningCheckDeliveryDate: 'idle',
  pickUpOrDeliveryCity: '',
  updatedDeliveryDurationStatus: 'idle',
  outOfDelivery: false,
  isThereActiveProducts: false,
  deliveryPrices: [],
  shopDeliveryPrices: {},
  partnersDeliveryPrices: {
    partnerDeliveryPrice: null,
    storageDeliveryPrice: null,
  },
  deliveryAddresses: [],
  maxMarketDeliveryPrice: 0,
  limitedCode: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetShoppingCartStatus: (state: CartState) => {
      return {
        ...state,
        cartLoading: initialState.cartLoading,
      };
    },
    setPickUpOrDeliveryCity: (state: CartState, action: PayloadAction<string>) => {
      state.pickUpOrDeliveryCity = action.payload;
    },
    setMaxMarketDeliveryPrice: (state: CartState, action: PayloadAction<number>) => {
      state.maxMarketDeliveryPrice = action.payload;
    },
    setCount: (state: CartState, action) => {
      state.count = action.payload;
    },
    setShowCheckoutSection: (state: CartState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showCheckoutSection: action.payload,
      };
    },
    setShowWarningCheckDeliveryDate: (state: CartState, action: PayloadAction<ShowWarning>) => {
      return {
        ...state,
        showWarningCheckDeliveryDate: action.payload,
      };
    },
    setLoggedOutcart(state: CartState, action) {
      let count = 0;
      Object.values(action.payload).forEach((products: any) => {
        products.forEach(product => {
          const { count: productCount } = product;
          count += productCount;
        });
      });

      return {
        ...state,
        loggedOutCart: action.payload,
        count,
      };
    },
    addProductToTheShoppingCart(state: CartState, action: any) {
      const { payload: showNotification = true } = action;
      return {
        ...state,
        addProductInShoppingCartStatus: showNotification
          ? 'success'
          : state.addProductInShoppingCartStatus,
        count: state.count + 1,
      };
    },
    resetStatus(state: CartState) {
      return {
        ...state,
        addProductInShoppingCartStatus: 'idle',
        limitedCode: null,
      };
    },
    reset() {
      return initialState;
    },
    setUpdatedDeliveryDurationProducts: (state: CartState, action: PayloadAction<any>) => {
      const { data: oldData, outOfDelivery: oldOutOfDelivery } = current(state);
      const { data, outOfDelivery, status } = action.payload;

      return {
        ...state,
        data: data || oldData,

        outOfDelivery: outOfDelivery || oldOutOfDelivery,
        updatedDeliveryDurationStatus: status,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLoggedOutUserCartCountThunk.fulfilled.type, (state: CartState, action: any) => {
        const {
          payload: { count },
        } = action;
        return {
          ...state,
          count,
        };
      })
      .addCase(getShoppingCartThunk.pending.type, (state: CartState, action: any) => {
        const {
          meta: {
            arg: { showLoading = true },
          },
        } = action;

        return {
          ...state,
          cartLoading: showLoading ? 'loading' : state.cartLoading,
        };
      })
      .addCase(getShoppingCartThunk.rejected.type, (state: CartState) => {
        return {
          ...state,
          cartLoading: 'failed',
        };
      })
      .addCase(
        getShoppingCartThunk.fulfilled.type,
        (state: CartState, action: PayloadAction<CartState>) => {
          const {
            payload: { data = {}, outOfDelivery },
          } = action;
          let initialCount = 0;
          const selectedProducts = [];
          let isThereActiveProducts = false;

          Object.values(data).forEach((products: any) => {
            products.forEach(product => {
              const { count, isActive, id } = product;
              if (isActive) {
                selectedProducts.push(id);
                if (!isThereActiveProducts) isThereActiveProducts = true;
              }
              initialCount += count;
            });
          });

          return {
            ...state,
            data,
            outOfDelivery,
            selectedProducts,
            isThereActiveProducts,
            count: initialCount,
            cartLoading: 'success',
          };
        }
      )

      .addCase(updatedDeliveryDurationProductsThunk.pending.type, (state: CartState) => {
        state.updatedDeliveryDurationStatus = 'loading';
      })
      .addCase(
        updatedDeliveryDurationProductsThunk.fulfilled.type,
        (state: CartState, action: any) => {
          const {
            payload: { data = {}, outOfDelivery },
          } = action;
          let isThereActiveProducts = false;

          Object.values(data).forEach((products: any) => {
            products.forEach(product => {
              const { isActive } = product;
              if (isActive) {
                if (!isThereActiveProducts) isThereActiveProducts = true;
              }
            });
          });

          state.data = data;
          state.outOfDelivery = outOfDelivery;
          state.isThereActiveProducts = isThereActiveProducts;
          state.updatedDeliveryDurationStatus = 'success';
        }
      )
      .addCase(updatedDeliveryDurationProductsThunk.rejected.type, (state: CartState) => {
        state.updatedDeliveryDurationStatus = 'failed';
      })
      .addCase(
        getShoppingCartDurationsForLoggedOutUserThunk.rejected.type,
        (state: CartState, action: any) => {
          const {
            meta: {
              arg: { showLoading },
            },
          } = action;

          return {
            ...state,
            cartLoading: showLoading ? 'failed' : state.cartLoading,
          };
        }
      )
      .addCase(
        getShoppingCartDurationsForLoggedOutUserThunk.pending.type,
        (state: CartState, action: any) => {
          const {
            meta: {
              arg: { showLoading },
            },
          } = action;

          return {
            ...state,
            cartLoading: showLoading ? 'loading' : state.cartLoading,
          };
        }
      )
      .addCase(
        getShoppingCartDurationsForLoggedOutUserThunk.fulfilled.type,
        (state: CartState, action: any) => {
          const {
            payload: { data, count, outOfDelivery },
            meta: {
              arg: { showLoading },
            },
          } = action;

          return {
            ...state,
            loggedOutCart: data,
            cartLoading: showLoading ? 'success' : state.cartLoading,
            count,
            outOfDelivery,
          };
        }
      )
      .addCase(changeProductStatusThunk.pending.type, (state: CartState) => {
        return {
          ...state,
          changeProductStatus: 'loading',
        };
      })
      .addCase(changeProductStatusThunk.rejected.type, (state: CartState) => {
        return {
          ...state,
          changeProductStatus: 'failed',
        };
      })
      .addCase(changeProductStatusThunk.fulfilled.type, (state: CartState, action: any) => {
        state = current(state);
        const {
          data: { shopingCartIds, isActive },
        } = action.meta.arg;

        const cardData = JSON.parse(JSON.stringify(state.data));
        let selectedProducts = [...state.selectedProducts];

        if (!isActive) {
          selectedProducts = selectedProducts.filter(selectedProductId => {
            return !shopingCartIds.includes(selectedProductId);
          });
        } else {
          selectedProducts = [...selectedProducts, ...shopingCartIds];
        }

        Object.values(cardData).forEach((products: any) => {
          products.forEach(product => {
            const { id } = product;
            if (shopingCartIds.includes(id)) {
              product.isActive = isActive;
            }
          });
        });

        return {
          ...state,
          selectedProducts,
          isThereActiveProducts: !!selectedProducts.length,
          changeProductStatus: 'success',
          data: cardData,
        };
      })
      .addCase(
        addProductCountInShoppingCartThunk.fulfilled.type,
        (state: CartState, action: AnyAction) => {
          const {
            meta: {
              arg: { showNotification = true },
            },
          } = action;
          return {
            ...state,
            addingToShoppingCartLoadingForProduct: null,
            addProductInShoppingCartStatus: showNotification
              ? 'success'
              : state.addProductInShoppingCartStatus,
            limitedCode: null,
          };
        }
      )
      .addCase(
        addProductCountInShoppingCartThunk.pending.type,
        (state: CartState, action: AnyAction) => {
          const {
            meta: {
              arg: { productId },
            },
          } = action;
          return {
            ...state,
            addingToShoppingCartLoadingForProduct: productId,
            addProductInShoppingCartStatus: 'loading',
            limitedCode: null,
          };
        }
      )
      .addCase(
        addProductCountInShoppingCartThunk.rejected.type,
        (state: CartState, action: AnyAction) => {
          const errorCode = action.payload;
          return {
            ...state,
            addProductInShoppingCartStatus: 'failed',
            limitedCode: errorCode,
          };
        }
      )
      .addCase(
        getDeliveryPricesThunk.fulfilled.type,
        (state: CartState, action: PayloadAction<{ data: DeliveryPrice[] }>) => {
          const { data } = action.payload;
          state.deliveryPrices = data;
        }
      )
      .addCase(
        getShopDeliveryPricesThunk.fulfilled.type,
        (state: CartState, action: PayloadAction<ShopDeliveryPrices>) => {
          state.shopDeliveryPrices = action.payload || {};
        }
      )
      .addCase(
        getPartnersDeliveryPricesThunk.fulfilled.type,
        (state: CartState, action: PayloadAction<PartnersDeliveryPrices>) => {
          state.partnersDeliveryPrices = action.payload;
        }
      )
      .addCase(
        getDeliveryAddressesListThunk.fulfilled.type,
        (state: CartState, action: PayloadAction<{ data: DeliveryPrice[] }>) => {
          const { data } = action.payload;
          state.deliveryAddresses = data;
        }
      )
      .addCase(
        getShoppingCartProductIdsThunk.fulfilled.type,
        (state: CartState, action: PayloadAction<ShoppingCartProductIds>) => {
          const { productIds } = action.payload;
          state.cartProductIds = productIds;
        }
      );
  },
});

export const useShoppingCart = (): CartState => useAppSelector((state: RootState) => state.cart);

export const getShoppingCartMemoized = createSelector(
  (state: RootState) => state.cart,
  cart => cart
);

export default cartSlice.reducer;
export const {
  setCount,
  setLoggedOutcart,
  reset,
  addProductToTheShoppingCart,
  resetStatus,
  setShowWarningCheckDeliveryDate,
  setShowCheckoutSection,
  resetShoppingCartStatus,
  setUpdatedDeliveryDurationProducts,
  setMaxMarketDeliveryPrice,
  setPickUpOrDeliveryCity,
} = cartSlice.actions;
