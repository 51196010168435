import { useAppDispatch } from 'store/hooks';

import { checkOTPLoginThunk, resetLogin } from 'store/auth/login';
import { defaultParams } from 'constants/auth/loginAndRegistrationConfigs';
import OTPVerificationBox from 'components/OTPVerificationBox';
import { useEffect } from 'react';

interface Props {
  text: string;
  phoneNumber: number;
  redirectionUrl?: string;
}

const PopupForVerification = ({ text, phoneNumber, redirectionUrl }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleOTPVerification = otp => {
    const params: any = {
      otp,
      phoneNumber,
      ...defaultParams,
    };
    if (redirectionUrl?.trim()) {
      params.redirectionUrl = redirectionUrl;
    }
    dispatch(checkOTPLoginThunk(params));
  };

  useEffect(() => {
    return () => {
      dispatch(resetLogin());
    };
  }, []);

  return (
    <OTPVerificationBox
      showPrivacyPolicy={true}
      handleOTPVerification={handleOTPVerification}
      phoneNumber={phoneNumber}
      text={text}
    />
  );
};

export default PopupForVerification;
