import { createAsyncThunk, createSlice, AnyAction } from '@reduxjs/toolkit';

import { create } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { CheckPhoneParams } from 'store/auth/preLogin/types';
import { Status } from 'store/types';
import handleErrorMessage from 'helpers/handleErroreMessage';

export interface PreRegistrationState {
  status: Status;
  phoneIsValid: boolean;
  errorMessage: null | string;
}

const initialState: PreRegistrationState = {
  status: 'idle',
  phoneIsValid: false,
  errorMessage: null,
};

const checkPhonePreRegistration = async (params: CheckPhoneParams, { rejectWithValue }: any) => {
  try {
    const response = await create(`${REACT_APP_BUYER_URL}/pre-register`, params);
    localStorage.removeItem('cartProducts');
    localStorage.setItem('phoneNumber', `${params.phoneNumber}`);
    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const checkPhonePreRegistrationThunk = createAsyncThunk(
  'preRegistration/checkPhone',
  checkPhonePreRegistration
);

export const PreRegistrationSlice = createSlice({
  name: 'preRegistration',
  initialState,
  reducers: {
    resetPreRegistration: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(checkPhonePreRegistrationThunk.pending.type, (state: PreRegistrationState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(checkPhonePreRegistrationThunk.fulfilled.type, (state: PreRegistrationState) => {
        return {
          ...state,
          phoneIsValid: true,
          status: 'success',
        };
      })
      .addCase(
        checkPhonePreRegistrationThunk.rejected.type,
        (state: PreRegistrationState, action: AnyAction) => {
          const errorMessage = handleErrorMessage(action.payload);
          return {
            ...state,
            errorMessage,
            status: 'failed',
          };
        }
      );
  },
});

export const { resetPreRegistration } = PreRegistrationSlice.actions;
export default PreRegistrationSlice.reducer;
